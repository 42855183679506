<template>
  <div class="hotHospital">
    <div class="hotContent">
      <div class="hisItem flex " v-for="(item, index) in hotList" :key="index" @click="clickDetail(item.id)">
        <div class="hisTop">
          <img :src="item.img" class="banImg" alt="" align="center" />
        </div>
        <div class="hisCenter">
          <div class="textOver">{{item.city_name}}</div>
          <div class="textOver">{{item.title}}</div>
        </div>
        <div class="hisAppointment">
          {{item.make_num}}<span style="color:#808080 ">预约</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hotList: {
      type: Array
    }
  },
  methods: {
    clickDetail (id) {
      this.$emit('clickDetail',id)
    }
  }
}
</script>

<style lang="less" scoped>
.hotHospital {
  .hotContent {
    margin-top: 20px;
    overflow-x: scroll;
    white-space: nowrap;

    .hisItem {
      margin-right: 10px;
      display: inline-block;
      border-radius: 10px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0px 1px 10px 1px rgba(0, 96, 240, 0.1);
      width: 100px;
      .hisTop {
        width: 110px;
        .banImg {
          width: 100%;
          height: 70px;
        }
      }
      .hisCenter {
        padding: 10px 10px 5px 10px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        
      }
      .hisAppointment {
        padding: 0 10px 5px 10px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #2163e9;
      }
    }
  }
  .hotContent::-webkit-scrollbar {
    display: none !important;
  }
  .flex {
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }
  .textOver {
    //超过一行省略号
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
