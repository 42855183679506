<template>
  <div class="details">
    <div class="content">
      <div class="search fz14 flex">
        <div class="position flex" @click="clickLocation">
          <img src="@/assets/school/dingwei.svg" class="searchImg" alt="" />
          <span>{{ cityValue }}</span>
        </div>
        <div class="sear">
          <van-search
            v-model="value"
            placeholder="搜索医院、科室、疾病、医生"
            background="#4fc08d"
            shape="round"
            :clearable="false"
            @focus="_search"
          />
        </div>
        <span @click="handlerMessage" style="margin-top:5px;"
          ><img
            src="@/assets/school/xiaoxi.svg"
            @click="handlerMessage"
            alt=""
            class="searchImg"
        /></span>
      </div>
      <div class="banner">
        <img src="../../assets/banner.png" class="bannerImg" alt="" />
      </div>
      <div class="product flex">
        <div class="student" @click="_tostudent">
          <div class="stuTop">学生护眼产品</div>
          <div class="stuCenter">
            <span style="color:#2163E9 ">4000+</span>
            <span>，尖端科技护眼产品</span>
          </div>
          <div class="stuBottom">
            儿童在家护眼
          </div>
        </div>
        <div class="school" @click="_toschool">
          <div class="schTop">学校护眼方案</div>
          <div class="schCenter">
            <span style="color:#2163E9 ">视觉环境</span>
            <span>，整体方案规划</span>
          </div>
          <div class="schBottom">
            改善学校环境
          </div>
        </div>
      </div>
      <div class="project ">
        <div class="proContent flex">
          <div class="item" @click="traning">
            <img src="@/assets/school/xunlian.svg" class="img30" alt="" />
            <div class="mt10">护眼训练</div>
          </div>
          <div class="item" @click="physician">
            <img src="@/assets/school/wenzhen.svg" class="img30" alt="" />
            <div class="mt10">在线问诊</div>
          </div>
          <div class="item" @click="checkMoreHis">
            <img src="@/assets/school/peijing.svg" class="img30" alt="" />
            <div class="mt10">在线配镜</div>
          </div>
          <div class="item" @click="medication">
            <img src="@/assets/school/yongyao.svg" class="img30" alt="" />
            <div class="mt10">门诊用药</div>
          </div>
        </div>
      </div>
      <!-- 热门医院 -->
      <div class="hotHispital mt30">
        <div class="hotTitle flex" @click="checkHospital">
          <div class="hisName">热门医院</div>
          <div class="more flex1">
            查看更多<van-icon name="arrow" color="#808080" />
          </div>
        </div>
        <vaeHotHospital
          :hotList="hotHospitalList"
          @clickDetail="clickDetail"
        ></vaeHotHospital>
      </div>
      <div class="healthAdvice mt30">
        <div class="healTitle flex" @click="checkMoreHeal">
          <div class="healName">健康资讯</div>
          <div class="more flex1">
            查看更多<van-icon name="arrow" color="#808080" />
          </div>
        </div>
        <div class="healContent">
          <vaeHealth
            :health="healthList"
            @checkDetail="checkDetail"
          ></vaeHealth>
        </div>
      </div>
      <div class="hotRecommended mt30">
        <div class="recTitle flex" @click="checkMoreRec">
          <div class="recName">热门推荐</div>
          <div class="more flex1"></div>
        </div>
        <!-- 热门推荐 -->
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="false"
          :offset="1"
          directio="down"
          @load="productLoad"
        >
          <vaeRecommend
            :recList="recList"
            @checkDetail="recDetail"
            style="margin-top:20px;"
          ></vaeRecommend>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import vaeHealth from '@/components/healthList.vue'
import vaeRecommend from '@/components/hotRecommend.vue'
import vaeHotHospital from '@/components/hotHospital.vue'
import getUserInfo from '@/api/getCode.js'
import { setLocal, getLocal } from '@/utils/local.js'
import wx from 'weixin-js-sdk'
import {
  hospitalList,
  topicList,
  productHotList,
  getSignPackage
} from '@/api/studentProduct'
export default {
  name: 'homePage',
  components: {
    vaeHealth,
    vaeRecommend,
    vaeHotHospital
  },
  data () {
    return {
      value: '',
      projectList: [
        { url: '@/assets/school/xunlian.svg', name: '护眼训练' },
        { url: '@/assets/school/wenzhen.svg', name: '在线问诊' },
        { url: '@/assets/school/peijing.svg', name: '在线配镜' },
        { url: '@/assets/school/yongyao.svg', name: '门诊用药' }
      ],
      cityValue: '南宁市',
      mockList: {},
      hotHospitalList: [],
      healthList: [],
      recList: [],
      latitude: '',
      longitude: '',
      listQuery: {
        page: 1, // 页数
        limit: 10
      },
      loading: false,
      finished: false,
      countAll: null
    }
  },
  created () {
    this.getHopital()
    this.getHealthList()
    this.getHotList(this.listQuery)
  },
  mounted () {
    // if (getLocal('cityName')) {
    //   this.cityValue = getLocal('cityName')
    // }else {
    //   this.getLocations()
    // }
    // this.signPackage()
  },
  methods: {
    // 鉴权
    async signPackage () {
      const pageUrl = location.href.split('#')[0] // 页面地址
      // const pageUrl = 'https://prd.eyebright.net.cn/' // 页面地址
      const res = await getSignPackage({ url: pageUrl })
      if (res.data.code == 200) {
        let config = res.data.data
        wx.config({
          // debug: true,
          appId: config.appId, // 必填，公众号的唯一标识
          timestamp: config.timestamp, // 必填，生成签名的时间戳
          nonceStr: config.nonceStr, // 必填，生成签名的随机串
          signature: config.signature, // 必填，签名
          jsApiList: ['checkJsApi', 'getLocation'] // 必填，需要使用的JS接口列表  'checkJsApi',
        })
        wx.ready(function () {
          wx.checkJsApi({
            jsApiList: ['getLocation'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {}
          })
        })
      }
    },
    // 获取位置
    getLocations () {
      const _this = this
      wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res1) {
          console.log(res1, 'ooooo')
          _this.latitude = res1.latitude // 纬度，浮点数，范围为90 ~ -90
          _this.longitude = res1.longitude // 经度，浮点数，范围为180 ~ -180。
        }
      })
      if (!getLocal('cityName')) {
        this.$dialog
          .alert({
            message: '申请获取当前所在城市',
            theme: 'round-button'
          })
          .then(() => {
            this.formSubmit()
            this.$forceUpdate()
          })
      }
    },
    // 逆地址编译
    formSubmit () {
      var _this = this
      this.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?', {
        location: _this.latitude + ',' + _this.longitude, // 经纬度
        key: 'HPIBZ-DU3LP-NDJDN-LCT2R-PP7Q3-G7BCH',
        output: 'jsonp' // output必须jsonp   不然会超时
      }).then(res => {
        // console.log(res, '腾讯地图')
        this.cityValue = res.result.address_component.city
        setLocal('cityName', res.result.address_component.city)
        this.$forceUpdate()
      })
    },
    async getHopital () {
      const res = await hospitalList({ type: 1, page: 1 })
      if (res.data.code == 200) {
        this.hotHospitalList = res.data.data.list
      }
    },
    // 健康咨询
    async getHealthList () {
      const res = await topicList()
      if (res.data.code == 200) {
        this.healthList = res.data.data
      }
    },
    // 热门推荐
    async getHotList (listQuery) {
      const res = await productHotList(listQuery)
      if (res.data.code == 200) {
        res.data.data.list.forEach(element => {
          this.recList.push(element)
        })
        if (this.recList.length >= res.data.data.count) {
          this.finished = true
        }
        this.countAll = res.data.data.count
      }
    },
    // 分页
    productLoad () {
      setTimeout(() => {
        if (this.listQuery.page >= Math.ceil(this.countAll / 10)) {
          this.loading = false
        }
        this.loading = true
        this.listQuery.page = this.listQuery.page + 1
        this.getHotList(this.listQuery)
        this.loading = false
      }, 1000)
    },
    // 授权
    authenticator (student_id) {
      const appid = 'wx66a54a1f39b651f1'
      const code = this.getUrlParam('code') // 获取请求路径中带code字段参数的方法
      const local = window.location.href // 获取当前的页面路径，回调地址
      if (code == null || code == '') {
        window.location.href =
          'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
          appid +
          '&redirect_uri=' +
          encodeURIComponent(local) +
          '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
      } else {
        this.getUserCode(code, student_id)
      }
    },
    async getUserCode (code, student_id) {
      console.log(code, '调用打印code')
      const res = await getUserInfo({ code: code, student_id: student_id })
      console.log(res, 'code调用')
    },
    getUrlParam (name) {
      console.log(name, 'code打印')
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg) //匹配目标参数
      console.log(r, '目标参数')
      if (r != null) {
        return unescape(r[2])
      } else {
        return null //返回参数值
      }
    },
    handlerMessage () {
      this.$router.push({ path: '/myNews' })
    },
    hosDetail () {},
    // 在线配镜
    checkMoreHis () {
      this.$router.push({ path: '/hospital?type=2' })
    },
    // 热门医院
    checkHospital () {
      this.$router.push({ path: '/hospital?type=1' })
    },
    checkMoreHeal () {
      this.$router.push({ path: '/healthAdvice' })
    },
    checkMoreRec () {},
    _search () {
      this.$router.push({ path: '/search' })
    },
    _tostudent () {
      this.$router.push({ path: '/student' })
    },
    _toschool () {
      this.$router.push({ path: '/school' })
    },
    clickLocation () {
      this.$router.push({ path: '/switchCity' })
      // this.formSubmit()
      // this.getLocations()
    },
    // 护眼训练
    traning () {
      this.$router.push({ path: '/eyeTraning' })
    },
    // 在线问诊
    physician () {
      this.$router.push({ path: '/eyePhysician' })
    },
    // 门诊用药
    medication () {
      this.$router.push({ path: '/medication' })
    },
    // 文章详情
    checkDetail (val) {
      this.$router.push({
        path: '/healthDetail',
        query: {
          id: val.id
        }
      })
    },
    // 商品详情
    recDetail (val) {
      this.$router.push({
        path: '/goodsDetail',
        query: {
          id: val
        }
      })
    },
    // 商品筛选
    menuChange (value) {
      console.log(value)
    },
    // 热门医院
    clickDetail (id) {
      this.$router.push({ path: '/hospitalInfo', query: { id: id } })
    }
  }
}
</script>

<style lang="less" scoped>
.details {
  background: #f8f9f9;
  height: calc(100vh - 145px);
  .content {
    padding-top: 10px;
    padding-bottom: 30px;
    .search {
      // padding: 5px 0;
      padding-right: 10px;

      .sear {
        flex: 1;
        padding-left: 15px;
      }
      .searchImg {
        width: 20px;
        height: 20px;
        padding-left: 11px;
      }
      .van-tab {
        color: #333333;
      }
      .van-search {
        padding: 0;
        background-color: #f8f9f9 !important;
        // box-shadow: 5px  5px 20px #cfcece;
      }
      .van-search__content {
        padding-left: 0;
        border: 1px solid #ccc;
        box-shadow: 0 0 3px #fcfcfc inset;
      }
      .van-cell {
        padding-left: 5px;
        line-height: 15px;
      }
      .van-search__content {
        background-color: #fff;
      }
    }
    .banner {
      padding: 0 3px;
      .bannerImg {
        width: 100%;
        height: auto;
        border-radius: 15px;
      }
    }
    .product {
      padding: 0 13px;
      .student {
        position: relative;
        width: 168px;
        height: 90px;
        padding: 5px 10px 0 10px;
        border-radius: 10px;
        background-color: #fff;
        background-image: url('../../assets/school/student.svg');
        background-repeat: no-repeat;
        background-position: 95px 20px;
        background-size: 40%;
        box-shadow: 0px 1px 10px 1px rgba(0, 96, 240, 0.1);
        .stuTop {
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #333333;
        }
        .stuCenter {
          font-size: 12px;
          color: #808080;
          line-height: 32px;
        }
        .stuBottom {
          padding: 0 3px;
          height: 20px;
          line-height: 20px;
          background-color: #2163e9;
          border-radius: 13px;
          color: #fff;
          font-size: 12px;
          position: absolute;
          left: 10px;
          top: 65px;
        }
      }
      .school {
        position: relative;
        width: 168px;
        height: 90px;
        margin-left: 10px;
        padding: 5px 10px 0 10px;
        border-radius: 10px;
        background-color: #fff;
        background-image: url('../../assets/school/school.svg');
        background-repeat: no-repeat;
        background-position: 95px 20px;
        background-size: 40%;
        box-shadow: 0px 1px 10px 1px rgba(0, 96, 240, 0.1);
        .schTop {
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #333333;
        }
        .schCenter {
          font-size: 12px;
          color: #808080;
          line-height: 32px;
        }
        .schBottom {
          padding: 0 3px;
          height: 20px;
          line-height: 20px;
          background-color: #01b3ff;
          border-radius: 13px;
          color: #fff;
          font-size: 12px;
          position: absolute;
          left: 10px;
          top: 65px;
        }
      }
    }
    .project {
      margin-top: 15px;
      padding: 0 13px;
      font-size: 14px;
      color: #333333;
      .proContent {
        background-color: #fff;
        padding: 10px 15px;
        border-radius: 10px;
        background-color: rgba();
        box-shadow: 0px 1px 10px 1px rgba(0, 96, 240, 0.1);

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
    .hotHispital {
      padding: 0 13px;
      .hotTitle {
        .hisName {
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #333333;
        }
        .more {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #808080;
        }
      }
    }
    .healthAdvice {
      padding: 0 15px;
      .healTitle {
        .healName {
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #333333;
        }
        .more {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #808080;
        }
      }
      .healContent {
        margin-top: 20px;
      }
    }
    .hotRecommended {
      .recTitle {
        padding: 0 15px;
        .recName {
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #333333;
        }
        .more {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #808080;
        }
      }
      .recSwith {
        margin-top: 15px;
        width: 100%;
        ::v-deep .van-dropdown-menu__bar {
          height: 40px;
        }
      }
    }
  }
  .mt10 {
    margin-top: 10px;
  }
  .img30 {
    width: 30px;
    height: 30px;
  }
  .mt30 {
    margin-top: 30px;
  }
  .flex1 {
    display: flex;
    align-items: center;
  }
}
</style>
